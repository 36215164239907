import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ApiService from "../lib/api_service";
import { useSelector } from "react-redux";
import UserAvatar from "./UserAvatar";
import StatusChip from "./StatusChip";
import UpdateTransactionModal from "./UpdateModal";

const api = new ApiService();
const AdminTransactionsTable = ({type}) => {
  const [open, setOpen] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useSelector((st) => st.user);

  const fetchData = async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await api.getRequest("api/v1/payments", user.token, {
        type: type,
        page: page + 1,
        limit: pageSize,
        search: searchTerm,
      });

      setData(response.data.transactions);
      setTotalRows(response.data.totalItems);
    } catch (error) {
      console.error("Error fetching transactions data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page, pageSize, searchTerm);
  }, [page, pageSize, searchTerm]);

  const handleViewClick = (transaction) => {
    // Implement the view action
    console.log("Viewing transaction:", transaction);
  };

  const handleUpdateClick = (transaction) => {
    // Implement the update action
    setTransaction(transaction);
    handleClose();
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 100 },
    {
      field: "user",
      headerName: "User",
      editable: false,
      flex: 1,
      renderCell: (params) => <UserAvatar user={params.row.user} />,
    },
    {
      field: "amountUSD",
      headerName: "Amount (USD)",
      editable: false,
      flex: 1,
    },
    {
      field: "amountKSH",
      headerName: "Amount (KSH)",
      editable: false,
      flex: 1,
    },
    { field: "mpesaCode", headerName: "Mpesa Code", editable: false, flex: 1 },
    {
      field: "transactionType",
      headerName: "Transaction Type",
      editable: false,
      flex: 1,
    },
    { field: "type", headerName: "Type", editable: false, flex: 1 },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      flex: 1,
      renderCell: (params) => <StatusChip status={params.row.status} />,
    },

    {
      field: "agent",
      headerName: "Agent",
      editable: false,
      flex: 1,
      renderCell: (params) => <UserAvatar user={params.row.agent} />,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      editable: false,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityIcon />}
          label="View"
          onClick={() => handleViewClick(params.row)}
        />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Update"
          onClick={() => handleUpdateClick(params.row)}
        />,
      ],
    },
  ];
  const handleClose = () => setOpen(!open);

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        sx={{ width: "100%" }}
        editMode="row"
        rows={data}
        columns={columns}
        pagination
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 25]}
        rowCount={totalRows}
        paginationMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        loading={loading}
        components={{
          Toolbar: GridToolbar,
        }}
        filterModel={{
          items: [
            {
              columnField: "mpesaCode",
              operatorValue: "contains",
              value: searchTerm,
            },
          ],
        }}
        onFilterModelChange={(filterModel) => {
          if (filterModel.items.length > 0) {
            setSearchTerm(filterModel.items[0].value || "");
          } else {
            setSearchTerm("");
          }
        }}
      />
      <UpdateTransactionModal
        transaction={transaction}
        handleClose={handleClose}
        open={open}
        type={type}
      />
    </div>
  );
};

export default AdminTransactionsTable;
