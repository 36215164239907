import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";

export default function Testimonials() {
  const testimonials = [
    {
      avatar: (
        <Avatar
          alt="Remy Sharp"
          src="https://img.freepik.com/free-photo/broker-looking-laptop-analyzing-stock-market-invest-trading-stocks-graph_169016-47428.jpg?size=626&ext=jpg"
        />
      ),
      name: "Alex Sharp",
      occupation: "Professional Trader",
      testimonial:
        "ForexPro's platform is incredibly intuitive and easy to use.I've been able to make informed decisions with real-time data.",
    },
    {
      avatar: (
        <Avatar
          alt="Sarah Baker"
          src="https://img.freepik.com/free-photo/broker-looking-laptop-analyzing-stock-market-invest-trading-stocks-graph_169016-47428.jpg?size=626&ext=jpg"
        />
      ),
      name: "Sarah Baker",
      occupation: "Day Trader",
      testimonial:
        "The educational resources offered by ForexPro are top-notch. I've learned so much and improved my trading skills.",
    },
    {
      avatar: (
        <Avatar
          alt="John  Smith"
          src="https://img.freepik.com/free-photo/broker-looking-laptop-analyzing-stock-market-invest-trading-stocks-graph_169016-47428.jpg?size=626&ext=jpg"
        />
      ),
      name: "John Smith",
      occupation: "Professional Trader",
      testimonial:
        "I love the real-time data provided by ForexPro. It has helped me stay ahead in the market.",
    },
  ];
  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography variant="h4" component="h2" color="text.primary">
          What Our Users Say
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex" }}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body1">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box>
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
