import { MoreVert } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  Link,
  MenuItem,
  Toolbar,
  Typography
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import heroImage from "../assets/57845.jpg";
import AboutUs from "../components/AboutUs";
import Features from "../components/Features";
import Logo from "../components/Logo";
import Testimonials from "../components/Testimonials";

const HeroSection = styled(Box)(({ theme }) => ({
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: `url(${heroImage})`,
  backgroundSize: "cover",
  color: theme.palette.common.white,
  textAlign: "center",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Light black tint
    zIndex: 1,
  },
  "& > *": {
    position: "relative",
    zIndex: 2,
  },
}));

function HomePage() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const { user } = useSelector((st) => st.user);
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      // setOpen(false);
    }
  };

  return (
    <>
      <Box>
        <AppBar
          position="fixed"
          sx={{
            boxShadow: 0,
            bgcolor: "transparent",
            backgroundImage: "none",
            mt: 2,
          }}
        >
          <Container maxWidth="lg">
            <Toolbar
              variant="regular"
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexShrink: 0,
                borderRadius: "999px",
                bgcolor:
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "rgba(0, 0, 0, 0.4)",
                backdropFilter: "blur(24px)",
                maxHeight: 40,
                border: "1px solid",
                borderColor: "divider",
                boxShadow:
                  theme.palette.mode === "light"
                    ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                    : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
              })}
            >
              <Logo variant={"h4"} />
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                }}
              >
                 <MenuItem
                  onClick={() => scrollToSection("aboutus")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    About Us
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("features")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    Features
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("testimonials")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    Testimonials
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("highlights")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    Highlights
                  </Typography>
                </MenuItem>
                {/* <MenuItem
                onClick={() => scrollToSection("pricing")}
                sx={{ py: "6px", px: "12px" }}
              >
                <Typography variant="body2" color="text.primary">
                  Pricing
                </Typography>
              </MenuItem> */}
                <MenuItem
                  onClick={() => scrollToSection("faq")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    FAQ
                  </Typography>
                </MenuItem>
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: 0.5,
                  alignItems: "center",
                }}
              >
                {user.token ? (
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    component={RouterLink}
                    to={"/user"}
                  >
                    Dashboard
                  </Button>
                ) : (
                  <>
                    {" "}
                    <Button
                      color="primary"
                      variant="text"
                      size="small"
                      component={RouterLink}
                      to="/signin"
                    >
                      Login
                    </Button>
                    <Button
                      color="primary"
                      variant="text"
                      size="small"
                      component={RouterLink}
                      to="/signup"
                    >
                      Sign Up
                    </Button>
                  </>
                )}
              </Box>
              <IconButton
                sx={{ display: { xs: "block", md: "none" } }}
                onClick={toggleDrawer}
              >
                <MoreVert />
              </IconButton>
            </Toolbar>
          </Container>
        </AppBar>

        <HeroSection>
          <Container maxWidth="md">
            <Typography variant="h2" component="h1" gutterBottom>
              Trade Forex Like a Pro
            </Typography>
            <Typography variant="h5" component="p" gutterBottom>
              Access real-time data, intuitive trading tools, and educational
              resources to help you succeed.
            </Typography>
            <Box mt={4}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={RouterLink}
                to="/signin"
              >
                Get Started
              </Button>
            </Box>
          </Container>
        </HeroSection>
        <Divider />
        <AboutUs/>
        <Divider />
        <Features />
        <Divider />
        <Testimonials />
        <Divider />

        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 4, sm: 8 },
            py: { xs: 8, sm: 10 },
            textAlign: { sm: "center", md: "left" },
          }}
        >
          <Box color="text.secondary" py={4}>
            <Container maxWidth="md" sx={{ textAlign: "center" }}>
              <Typography variant="body2">
                © {new Date().getFullYear()} <Logo variant={"h5"} /> All rights
                reserved.
              </Typography>
              <Box mt={2}>
                <Link href="#" color="inherit" sx={{ mx: 1 }}>
                  Privacy Policy
                </Link>
                <Link href="#" color="inherit" sx={{ mx: 1 }}>
                  Terms of Service
                </Link>
              </Box>
            </Container>
          </Box>{" "}
        </Container>
      </Box>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={(theme) => ({
          bgcolor:
            theme.palette.mode === "light"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)",
          backdropFilter: "blur(5px)",
        })}
      >
        <Toolbar>
          <Logo />
        </Toolbar>
        <Box sx={{ width: 250, p: 2 }}>
          <MenuItem
            onClick={() => {
              toggleDrawer();
              scrollToSection("features");
            }}
          >
            <Typography variant="body2" color="text.primary">
              Features
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              toggleDrawer();
              scrollToSection("testimonials");
            }}
          >
            <Typography variant="body2" color="text.primary">
              Testimonials
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              toggleDrawer();
              scrollToSection("highlights");
            }}
          >
            <Typography variant="body2" color="text.primary">
              Highlights
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              toggleDrawer();
              scrollToSection("faq");
            }}
          >
            <Typography variant="body2" color="text.primary">
              FAQ
            </Typography>
          </MenuItem>
          {user.token ? (
            <MenuItem component={RouterLink} to="/user" onClick={toggleDrawer}>
              <Typography variant="body2" color="text.primary">
                Dashboard
              </Typography>
            </MenuItem>
          ) : (
            <>
              <MenuItem
                component={RouterLink}
                to="/signin"
                onClick={toggleDrawer}
              >
                <Typography variant="body2" color="text.primary">
                  Login
                </Typography>
              </MenuItem>
              <MenuItem
                component={RouterLink}
                to="/signup"
                onClick={toggleDrawer}
              >
                <Typography variant="body2" color="text.primary">
                  Sign Up
                </Typography>
              </MenuItem>
            </>
          )}
        </Box>
      </Drawer>{" "}
    </>
  );
}

export default HomePage;
