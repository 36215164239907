import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import io from "socket.io-client";
import CoreChart from "../../components/Chart";

const SIGNAL_API_URL = "http://localhost:5000"; // Your Socket.IO server URL

const SignalDetailPage = () => {
  const { id } = useParams();
  const [series, setSeries] = useState([
    {
      name: "Price",
      data: [],
    },
  ]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Initialize Socket.IO connection
    const socket = io(SIGNAL_API_URL, {
      timeout: 30000,
      transports: ["websocket"],
    });

    // On connection success
    socket.on("connect", () => {
      console.log("Connected to Socket.IO server");
      setLoading(false);
    });

    // Handle stock data update from socket
    socket.on("stock-update", (response) => {
      const processedData = processStockData(response);
      setSeries((prevSeries) => [
        {
          ...prevSeries[0],
          data: processedData, // Update the series with the processed data
        },
      ]);
    });

    // Handle disconnection
    socket.on("disconnect", () => {
      console.log("Disconnected from the Socket.IO server");
    });

    // Handle connection error
    socket.on("connect_error", (error) => {
      console.error("Connection Error:", error);
    });

    // Handle general socket error
    socket.on("error", (error) => {
      console.error("Socket.IO Error:", error);
    });

    // Cleanup function to disconnect Socket.IO connection
    return () => {
      socket.disconnect();
    };
  }, []);

  // Process stock data received from socket
  const processStockData = (response) => {
    const { history } = response;

    // Extract the necessary data for charting
    const processedHistory = history.map((entry) => ({
      x: new Date(entry.date).getTime(),
      y: [
        parseFloat(entry.open),
        parseFloat(entry.high),
        parseFloat(entry.low),
        parseFloat(entry.close),
      ],
    }));

    // Adding the current stock price to the series
    const latestPriceData = {
      x: new Date().getTime(), // Use current time for latest update
      y: [
        parseFloat(response.open),
        parseFloat(response.high),
        parseFloat(response.low),
        parseFloat(response.price), // Add current price as close value
      ],
    };

    return [...processedHistory, latestPriceData];
  };

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : series && series[0].data.length > 0 ? (
        <CoreChart data={series} /> // Render chart when data is available
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default SignalDetailPage;
