import { Box } from "@mui/material";
import React from "react";
import CoreChart from "../../components/Chart";

export default function UserDashboard() {
  return (
    <Box>
      <CoreChart />
    </Box>
  );
}
