import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import ApiService from "../../lib/api_service";
import { loginUser } from "../../store/actions/auth";

const api = new ApiService();

export default function VerifyEmail() {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = JSON.stringify({ email });
    const response = await api.postRequest("api/v1/auth/forgot-password", body);
    if (!response.status) {
      setError(response.message);
      return;
    }
    let token = response.data.token;
    const me = response.data.user;
    const usr = { ...me, token: token, isVerified: true };
    dispatch(loginUser(usr));
    setSuccess(response.message);
    navigate("/confirm-otp", { state: { changePassword: true } });
  };

  return (
    <Container
    maxWidth="xs"
    sx={{
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      px: 2,
    }}
  >
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: 2,
        py: 4,
        borderRadius: 2,
        width: "100%",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          flexGrow: 1,
          p: 1,
          maxWidth: 400,
          width: "100%",
        }}
      >
        {" "}
        <Box sx={{ mb: 2 }}>
          <Logo />
        </Box>
        <Typography variant="h5" gutterBottom>
          Verify your email
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
            required
            type="email"
            placeholder="johndoe@gmail.com"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {success}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Verify Email
          </Button>
        </form>
      </Card>
    </Box>
    </Container>
  );
}
