import { Alert, Box, Button, Card, Container, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import OtpInput from "../../components/OtpInput";
import ApiService from "../../lib/api_service";
import { loginUser } from "../../store/actions/auth";

const api = new ApiService();

export default function ConfirmOtp() {
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = useSelector((st) => st.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  console.log("LOCATION", location);
  const handleSubmit = async (event) => {
    event.preventDefault();
    let email = user.user.email;
    let code = Number.parseInt(otp.join(""));
    const body = JSON.stringify({ email, code });

    const endpoint = location.state.changePassword
      ? "api/v1/auth/forgot-password-verify"
      : "api/v1/auth/account-verify";

    const response = await api.signup(endpoint, body);

    if (!response.status) {
      setError(response.message);
      return;
    }

    let token = response.data.token;
    const me = response.data.user;
    const usr = { ...me, token: token, isVerified: true };
    dispatch(loginUser(usr));
    setSuccess(response.message);

    navigate(
      location.state.changePassword
        ? "/reset-password"
        : user.user.role.name === "USER"
        ? "/user"
        : "/admin"
    );
  };

  const resendCode = async (event) => {
    event.preventDefault();
    let email = user.user.email;
    const body = JSON.stringify({ email });
    const endpoint = location.state.changePassword
      ? "api/v1/auth/resend-password-verify"
      : "api/v1/auth/resend-account-verify";
    const response = await api.signup(endpoint, body);

    if (!response.status) {
      setError(response.message);
      return;
    }

    setSuccess(response.message);
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        px: 2,
      }}
    >
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 2,
          py: 4,
          borderRadius: 2,
          width: "100%",
        }}
      >
        <Card
          elevation={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            flexGrow: 1,
            p: 1,
            maxWidth: 400,
            width: "100%",
          }}
        >
          <Box sx={{ mb: 2 }}>
            <Logo />
          </Box>
          <Typography variant="h5" gutterBottom>
            Verify OTP
          </Typography>
          <form onSubmit={handleSubmit}>
            {/* <Box sx={{width:"100%"}}> */}
            <OtpInput otp={otp} setOtp={setOTP} />
            {/* </Box> */}

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {success}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Confirm OTP
            </Button>
            <Button
              onClick={resendCode}
              fullWidth
              variant="outlined"
              color="secondary"
              sx={{ mt: 1 }}
            >
              Resend Code
            </Button>
          </form>
        </Card>
      </Box>
    </Container>
  );
}
