import { Chip } from '@mui/material';

const StatusChip = ({ status }) => {
  const statusColors = {
    pending: { label: "Pending", color: "warning" },
    processing: { label: "Processing", color: "info" },
    completed: { label: "Completed", color: "success" },
  };

  const { label, color } = statusColors[status] || { label: "Unknown", color: "default" };

  return <Chip label={label} color={color} />;
};

export default StatusChip;
