import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const LogoText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(45deg, #FFD700 30%, #8B4513 90%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontWeight: 700,
}));

const Logo = ({ variant }) => {
  return <LogoText variant={variant ?? "h4"}>ForexPro</LogoText>;
};

export default Logo;
