import React from "react";
import { useSelector } from "react-redux";
import {
  Button,
  TextField,
  Card,
  Typography,
  Tabs,
  Tab,
  Box,
  IconButton,
} from "@mui/material";
import ApiService from "../../lib/api_service";
import { Share } from "@mui/icons-material";

const api = new ApiService();
export default function ReferralPage() {
  const [activeTab, setActiveTab] = React.useState("referrals");
  const [referralLink, setReferralLink] = React.useState("");
  const [referralEarnings, setReferralEarnings] = React.useState({
    active_referrals: 0,
    total_referrals: 0,
    totalReferralBonus: 0,
    referralCode: "",
  });
  const { user } = useSelector((st) => st.user);
  const [isSupported, setIsSupported] = React.useState(false);

  React.useEffect(() => {
    fetchReferralData();
    addReferralCodeToLink();
  }, [user]);

  React.useEffect(() => {
    if (navigator.share) {
      setIsSupported(true);
    }
  }, []);

  const fetchReferralData = async () => {
    try {
      const response = await api.getReq(
        "api/v1/referrals/me",
        user.token
      );
      if (!response.status) {
        return;
      }
      setReferralEarnings(response);
    } catch (error) {
      console.error("Error fetching transactions data:", error);
    }
  };

  const addReferralCodeToLink = () => {
    const currentUrl = window.location.origin;
    const url = new URL(currentUrl);
    url.searchParams.append("ref", referralEarnings.referralCode);
    setReferralLink(url.toString());
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    alert("Referral link copied to clipboard!");
  };

  const handleShareLink = async () => {
    try {
      await navigator.share({
        title: "Share this link to your friends and family",
        text: "Earn bonuses on every referral",
        url: referralLink,
      });
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        p: 1,
        minHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "text.secondary",
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Refer and Earn Money
      </Typography>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Your Referral Link:
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              variant="outlined"
              value={referralLink}
              InputProps={{ readOnly: true }}
              fullWidth
            />
          </Box>

          <Button variant="outlined" color="primary" onClick={handleCopyLink}>
            Copy
          </Button>
          {isSupported && (
            <IconButton
              variant="outlined"
              color="primary"
              sx={{ ml: 2 }}
              onClick={handleShareLink}
            >
              <Share />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
        <Card sx={{ p: 2, bgcolor: "background.default", textAlign: "center" }}>
          <Typography variant="subtitle1" component="p">
            Earning
          </Typography>
          <Typography variant="h6" component="p">
            {referralEarnings.totalReferralBonus
              ? `$${referralEarnings.totalReferralBonus.toFixed(2)}`
              : "$0.00"}
          </Typography>
        </Card>
        <Card sx={{ p: 2, bgcolor: "background.default", textAlign: "center" }}>
          <Typography variant="subtitle1" component="p">
            Referrals
          </Typography>
          <Typography variant="h6" component="p">
            {referralEarnings.total_referrals || 0}
          </Typography>
        </Card>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="referral tabs"
        >
          <Tab value="referrals" label="Latest Referrals" />
          <Tab value="earnings" label="Latest Earnings" />
        </Tabs>
        <Box
          sx={{
            p: 2,
            bgcolor: "background.default",
            borderTop: 2,
            borderColor: "divider",
          }}
        >
          {activeTab === "referrals" && <LatestReferrals />}
          {activeTab === "earnings" && <LatestEarnings />}
        </Box>
      </Box>
    </Box>
  );
}

// Example placeholders for the LatestReferrals and LatestEarnings components
const LatestReferrals = () => (
  <Typography variant="body1">
    Here you can see the latest referrals.
  </Typography>
);

const LatestEarnings = () => (
  <Typography variant="body1">Here you can see the latest earnings.</Typography>
);
