import {
  AttachMoney,
  CalendarToday,
  Cancel,
  Delete,
} from "@mui/icons-material";
import TrendingUp from "@mui/icons-material/TrendingUp";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const TradingSignalCard = ({ signal, onBuy, onDelete }) => {
  const { user } = useSelector((st) => st.user);
  return (
    <Card
      variant="elevation"
      sx={{
        p: 1,
        width: "100%",
        maxWidth: { xs: "100%", sm: "100%", md: "90%" },
        backgroundColor: "background.paper",
        borderRadius: 3,
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.2s",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
          bgcolor="primary.main"
          color="white"
          p={1}
          borderRadius={2}
        >
          <Typography variant="h5" fontWeight="bold">
            {signal.symbol}
          </Typography>
          <Chip
            label={signal.action.toUpperCase()}
            color={signal.action === "buy" ? "success" : "error"}
            sx={{ fontWeight: "bold", fontSize: "0.875rem" }}
          />
        </Box>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={6} display="flex" alignItems="center">
            <AttachMoney sx={{ mr: 1, color: "success.dark" }} />
            <Typography variant="subtitle2" color="textSecondary">
              Entry Price:
            </Typography>
            <Typography variant="h6">${signal.entryPrice}</Typography>
          </Grid>

          <Grid item xs={6} display="flex" alignItems="center">
            <TrendingUp sx={{ mr: 1, color: "success.dark" }} />{" "}
            <Typography variant="subtitle2" color="textSecondary">
              Target Price:
            </Typography>
            <Typography variant="h6">${signal.targetPrice}</Typography>
          </Grid>

          <Grid item xs={6} display="flex" alignItems="center">
            <Cancel sx={{ mr: 1, color: "warning.dark" }} />
            <Typography variant="subtitle2" color="textSecondary">
              Stop Loss:
            </Typography>
            <Typography variant="h6">${signal.stopLoss}</Typography>
          </Grid>

          <Grid item xs={6} display="flex" alignItems="center">
            <CalendarToday sx={{ mr: 1, color: "warning.dark" }} />
            <Typography variant="subtitle2" color="textSecondary">
              Timeframe:
            </Typography>
            <Typography variant="h6">{signal.timeframe}</Typography>
          </Grid>
        </Grid>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Chip
            label={`Confidence: ${signal.confidenceLevel}`}
            sx={{
              backgroundColor:
                signal.confidenceLevel === "High" ? "#4caf50" : "#ff9800",
              color: "white",
              fontWeight: "bold",
            }}
          />
          {user.role.name === "USER" ? (
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontWeight: "bold",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
              }}
              onClick={() => onBuy(signal)}
            >
              Buy Signal
            </Button>
          ) : (
            <>
              <Box sx={{ display: "flex" }}>
                {" "}
                <IconButton color="error" onClick={() => onDelete(signal)}>
                  <Delete />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TradingSignalCard;
