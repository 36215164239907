import { Box } from "@mui/material";
import React from "react";
import TransactionsTable from "../../components/DataGridWidget";

export default function UserDeposits() {
  return (
    <Box>
      <TransactionsTable type="deposit"/>
    </Box>
  );
}
