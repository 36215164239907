export function convertToKsh({ amountInUsd, agentRate }) {
  if (!amountInUsd || !agentRate) {
    return 0;
  }
  const amountInKsh =
    Number.parseInt(amountInUsd) * Number.parseFloat(agentRate);

  return new Intl.NumberFormat("en-KE", {
    style: "currency",
    currency: "KES",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amountInKsh);
}

export function formatCurreny({ currency, amount }) {
  if (!currency || !amount) {
    return 0;
  }

  return new Intl.NumberFormat("en-KE", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
}
