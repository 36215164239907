import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BooleanChip from "../../components/BooleanChip";
import ApiService from "../../lib/api_service";

const api = new ApiService();
const AdminUsers = () => {
  const [open, setOpen] = useState(false);
  // const [transaction, setTransaction] = useState({});
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useSelector((st) => st.user);

  const fetchData = async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await api.getRequest("api/v1/users", user.token, {
        page: page + 1,
        limit: pageSize,
        search: searchTerm,
      });

      setData(response.data.users);
      setTotalRows(response.data.totalItems);
    } catch (error) {
      console.error("Error fetching transactions data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page, pageSize, searchTerm);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, searchTerm]);

  const handleViewClick = (transaction) => {
    // Implement the view action
    console.log("Viewing transaction:", transaction);
  };

  const handleUpdateClick = (transaction) => {
    // Implement the update action
    // setTransaction(transaction);
    handleClose();
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 100 },
    {
      field: "name",
      headerName: "Name",
      editable: false,
      flex: 1,
      // renderCell: (params) => <UserAvatar user={params.row.user} />,
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      flex: 1,
    },
    {
      field: "mobileNumber",
      headerName: "Phone Number",
      editable: false,
      flex: 1,
    },
    { field: "balance", headerName: "Balance", editable: false, flex: 1 },
    {
      field: "isBlocked",
      headerName: "Blocked",
      editable: false,
      flex: 1,
      renderCell: (params) => <BooleanChip value={params.row.isBlocked} />,
    },
    {
      field: "isVerified",
      headerName: "Status",
      editable: false,
      flex: 1,
      renderCell: (params) => <BooleanChip value={params.row.isVerified} />,
    },

    {
      field: "totalTransactions",
      headerName: "Transactions",
      editable: false,
      flex: 1,
      // renderCell: (params) => <UserAvatar user={params.row.agent} />,
    },
    {
      field: "referralEarnings",
      headerName: "Referral",
      editable: false,
      flex: 1,
      // renderCell: (params) => <UserAvatar user={params.row.agent} />,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      editable: false,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityIcon />}
          label="View"
          onClick={() => handleViewClick(params.row)}
        />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Update"
          onClick={() => handleUpdateClick(params.row)}
        />,
      ],
    },
  ];
  const handleClose = () => setOpen(!open);

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        sx={{ width: "100%" }}
        editMode="row"
        rows={data}
        columns={columns}
        pagination
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 25]}
        rowCount={totalRows}
        paginationMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        loading={loading}
        components={{
          Toolbar: GridToolbar,
        }}
        filterModel={{
          items: [
            {
              columnField: "mpesaCode",
              operatorValue: "contains",
              value: searchTerm,
            },
          ],
        }}
        onFilterModelChange={(filterModel) => {
          if (filterModel.items.length > 0) {
            setSearchTerm(filterModel.items[0].value || "");
          } else {
            setSearchTerm("");
          }
        }}
      />
    </div>
  );
};

export default AdminUsers;
