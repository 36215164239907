import React from "react";
import { Chip } from "@mui/material";

const BooleanChip = ({ value }) => {
  return (
    <Chip
      label={value ? ` Yes` : ` No`}
      color={value ? "success" : "default"}
      style={{ margin: "0 8px 8px 0" }}
    />
  );
};

export default BooleanChip;
