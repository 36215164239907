import axios from "axios";

class ApiService {
  baseURL = process.env.REACT_APP_API_URL;

  async signup(endpoint, body) {
    const url = `${this.baseURL}/${endpoint}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    const data = await response.json();
    console.log("DATA", data);
    return data;
  }

  async signup(endpoint, body) {
    const url = `${this.baseURL}/${endpoint}`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    const res = await response.json();

    return res;
  }
  //accountverify
  async postRequest(endpoint, body) {
    const url = `${this.baseURL}/${endpoint}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: body,
    });
    const res = await response.json();

    return res;
  }
  async postRequestwithBearer(endpoint, body, token) {
    const url = `${this.baseURL}/${endpoint}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: body,
    });
    const res = await response.json();

    return res;
  }
  async putRequestwithBearer(endpoint, body, token) {
    const url = `${this.baseURL}/${endpoint}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: body,
    });
    const res = await response.json();

    return res;
  }
  //put request
  async putRequest(endpoint, body) {
    const url = `${this.baseURL}/${endpoint}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: body,
    });
    const res = await response.json();

    return res;
  }
  //delete resource
  async deleteRequest(endpoint, token) {
    const url = `${this.baseURL}/${endpoint}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    const res = await response.json();

    return res;
  }

  async multipartRequest(endpoint, body) {
    console.log("BODY", body);
    const url = `${this.baseURL}/${endpoint}`;
    const response = await axios.post(url, body);
    const res = await response.json();

    return res;
  }
  async postRequestWithToken(endpoint, body, token) {
    const url = `${this.baseURL}/${endpoint}?token=${token}`;
    console.log("URL TOKEN", url);
    const response = await fetch(url, {
      method: "POST",
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();

    return res;
  }
  async getRequest(endpoint, token, params = {}) {
    const queryString = new URLSearchParams(params).toString();
    const url = `${this.baseURL}/${endpoint}?${queryString}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  }
  async getReq(endpoint, token) {
    const url = `${this.baseURL}/${endpoint}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  }
}

export default ApiService;
