import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../../lib/api_service";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const api = new ApiService();
const AgentManagement = () => {
  const [agents, setAgents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [selectedAgent, setSelectedAgent] = useState(null);
  const user = useSelector((st) => st.user).user;
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobileNumber: "",
    active: true,
    imageUrl: "",
    rates: 0,
    type: "",
  });

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      const response = await api.getRequest("api/v1/agents");
      setAgents(response.data);
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (editMode) {
        await api.putRequestwithBearer(
          `api/v1/agents/${selectedAgent.id}`,
          JSON.stringify(formData),
          user.token
        );
      } else {
        await api.postRequestwithBearer(
          "api/v1/agents",
          JSON.stringify(formData),
          user.token
        );
      }
      fetchAgents();
      setOpenModal(false);
      setFormData({
        firstname: "",
        lastname: "",
        email: "",
        mobileNumber: "",
        active: true,
        imageUrl: "",
      });
      setEditMode(false);
    } catch (error) {
      console.error("Error saving agent:", error);
    }
  };

  const handleEdit = (agent) => {
    setSelectedAgent(agent);
    setFormData(agent);
    setEditMode(true);
    setOpenModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await api.deleteRequest(`api/v1/agents/${id}`);
      fetchAgents();
    } catch (error) {
      console.error("Error deleting agent:", error);
    }
  };

  const columns = [
    // { field: "id", headerName: "ID", minWidth: 150 },
    { field: "firstname", headerName: "First Name", minWidth: 150, flex: 1 },
    { field: "lastname", headerName: "Last Name", minWidth: 150, flex: 1 },
    { field: "email", headerName: "Email", minWidth: 150, flex: 1 },
    { field: "type", headerName: "Type", minWidth: 150, flex: 1 },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      minWidth: 150,
      flex: 1,
    },
    { field: "active", headerName: "Active", minWidth: 150, flex: 1 },
    { field: "rates", headerName: "Rates", minWidth: 150, flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 150,
      type: "actions",
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEdit(params.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={() => handleDelete(params.row.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div style={{}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        {" "}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          Add Agent
        </Button>
      </Box>
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          width: "100%",
          display: { xs: "none", sm: "flex" },
          pointerEvents: "none",
        }}
      >
        <DataGrid
          rows={agents}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{ width: "100%" }}
        />
      </Card>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={style}>
          <Typography variant="h6" gutterBottom>
            {editMode ? "Edit Agent" : "Add Agent"}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="First Name"
            name="firstname"
            value={formData.firstname}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Last Name"
            name="lastname"
            value={formData.lastname}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Mobile Number"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleInputChange}
          />
          <FormControl
            fullWidth
            size="small"
            sx={{ marginTop: 1, marginBottom: 1 }}
          >
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              name="type"
              labelId="type"
              id="typet"
              value={formData.type}
              label="type"
              onChange={handleInputChange}
            >
              <MenuItem value="deposit">Deposit</MenuItem>
              <MenuItem value="withdraw">Withdraw</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Currency Rates"
            type="number"
            name="rates"
            value={formData.rates}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Image URL"
            name="imageUrl"
            value={formData.imageUrl}
            onChange={handleInputChange}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ mt: 2 }}
          >
            {editMode ? "Update" : "Create"}
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default AgentManagement;
