import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useSelector } from "react-redux";
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    const randomizedValue = (value + Math.floor(Math.random() * 256)) % 256;

    color += `00${randomizedValue.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const User = ({}) => {
  const { user } = useSelector((st) => st.user);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 1,
        borderRadius: 1,
        bgcolor: "background.paper",
        boxShadow: 1,
      }}
    >
      <Avatar {...stringAvatar(`${user.firstname} ${user.lastname}`)}  sx={{mr:1}}/>

      <Box>
        <Typography variant="body1" color="textSecondary">
          {`${user.firstname} ${user.lastname}`}
        </Typography>
        <Typography variant="p" color="textPrimary">
          {user.email}
        </Typography>
      </Box>
    </Box>
  );
};

export default User;
