// src/components/UserDashboardLayout.js
import {
  ArrowDownward,
  Logout,
  MenuOpen,
  Notifications,
  Paid,
  SendToMobile,
  Settings,
} from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { yellow } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";
import AddFundsModal from "../components/AddFundsModal";
import Balance from "../components/Balance";
import Logo from "../components/Logo";
import TradeWidget from "../components/TradeWidget";
import User from "../components/User";
import UserBalance from "../components/UserBalance";
import { useDispatch } from "react-redux";
import { logOut } from "../store/actions/auth";
import WithdrawFundsModal from "../components/WithdrawFundsModal";
import { useTheme } from "@emotion/react";

const drawerWidth = 240;

const UserDashboardLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [currentRoute, setCurrentRoute] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [withdrawOpen, setWithdrawOpen] = React.useState(false);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const extractRouteName = (path) => {
    const parts = path.split("/").filter(Boolean); // Split by '/' and remove empty parts
    return parts.length > 1 ? parts[1] : parts[0]; // Extract the part after 'admin'
  };

  useEffect(() => {
    setCurrentRoute(extractRouteName(location.pathname));
  }, [location]);

  const handleClose = () => setOpen(!open);
  const handleProfileLogout = () => {
    dispatch(logOut());
    handleMenuClose();
  };
  const handleWithdrawModal = () => setWithdrawOpen(!withdrawOpen);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <AppBar
        position="fixed"
        sx={{
          // width: isMobile ? "100%" : `calc(100% - ${drawerWidth}px)`,
          // ml: isMobile ? 0 : `${drawerWidth}px`,
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: isMobile ? 0 : 2,
        }}
      >
        <Container
          maxWidth={isMobile ? false : "lg"}
          sx={{
            width: isMobile ? "100%" : "auto",
          }}
          disableGutters={isMobile}
        >
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              paddingRight: "4px",
              paddingLeft: "8px",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: isMobile ?? "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuOpen />
              </IconButton>
            )}
            <Box
              sx={{
                display: { xs: "flex", md: "flex" },
                gap: 1,
                alignItems: "center",
              }}
            >
              {" "}
              <Typography
                variant="h6"
                sx={{ flexGrow: 1, textTransform: "capitalize" }}
              >
                {currentRoute}
              </Typography>
            </Box>

         
            <Box
              sx={{
                display: { xs: "flex", md: "flex" },
                gap: 1,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 1,
                  backgroundColor: "background.paper",
                  boxShadow: 3,
                  padding: 1,
                  margin: 1,
                  minWidth: 200,
                }}
              >
                <Button
                  variant="outlined"
                  // color="warning.main" // Updated color
                  startIcon={<SendToMobile />} // Updated icon
                  onClick={handleWithdrawModal}
                >
                  Withdraw
                </Button>
              </Box>
              <Box
                sx={{
                  marginRight: 1,
                  marginLeft: 1,
                  display: { sm: "none", md: "block" },
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<Paid />}
                  onClick={handleClose}
                  size="small"
                  // color="warning.main"
                >
                  Add Funds
                </Button>
              </Box>
              <Box
                sx={{
                  marginRight: isMobile ? 1 : 1,
                  marginLeft: isMobile ? 1 : 1,
                }}
              >
                <Badge badgeContent={4} color="secondary">
                  <Notifications color="action" />
                </Badge>
              </Box>
              {/* <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: isMobile ? 1 : 1 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                </IconButton>
              </Tooltip> */}
                 <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 1,
                alignItems: "center",
              }}
            >
              <UserBalance />
              {/* <TradeWidget
                trade={{
                  direction: "up",
                  startTime: "2024-08-31T12:00:00Z",
                  duration: 3600, // 60 minutes
                  amount: 100.0,
                  isActive: true,
                }}
              /> */}
            </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={(theme) => ({
          width: drawerWidth,
          // backdropFilter: "blur(5px)",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backdropFilter: "blur(5px)",
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
          },
        })}
        anchor="left"
      >
        <Toolbar>
          <Logo />
        </Toolbar>
        <Box sx={{ flexGrow: 1, mt: 2 }}>
          <List>
            <ListItemButton
              variant="outlined"
              component={RouterLink}
              to="/user/dashboard"
              selected={location.pathname === "/user/dashboard"}
            >
              <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton
              component={RouterLink}
              to="/user/signals"
              selected={location.pathname === "/user/signals"}
            >
              <ListItemText primary="Trading Signals" />
            </ListItemButton>
            <ListItemButton
              component={RouterLink}
              to="/user/withdrawals"
              selected={location.pathname === "/user/withdrawals"}
            >
              <ListItemText primary="Withdrawals" />
            </ListItemButton>

            <ListItemButton
              component={RouterLink}
              to="/user/deposits"
              selected={location.pathname === "/user/deposits"}
            >
              <ListItemText primary="Deposits" />
            </ListItemButton>
            <ListItemButton
              component={RouterLink}
              to="/user/referrals"
              selected={location.pathname === "/user/referrals"}
            >
              <ListItemText primary="Referrals" />
            </ListItemButton>

            <ListItemButton
              component={RouterLink}
              to="/user/settings"
              selected={location.pathname === "/user/settings"}
            >
              <ListItemText primary="Settings" />
            </ListItemButton>
          </List>
        </Box>

        <Divider />
        <Box sx={{ p: 2 }}>
          <Balance balance={12345.67} />
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <User />
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: isMobile ? 0.7 : 3,
          width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
      <AddFundsModal handleClose={handleClose} open={open} />
      <WithdrawFundsModal
        handleClose={handleWithdrawModal}
        open={withdrawOpen}
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        slotProps={{
          paper: {
            elevation: 4,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",

              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleMenuClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleProfileLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserDashboardLayout;
