import React from "react";
import { Container, Typography, Box, Button, Grid } from "@mui/material";

const AboutUs = () => {
  return (
    <Container id="aboutus" maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box textAlign="center" mb={4}>
        <Typography variant="h3" gutterBottom>
          🌟 Maximize Your Trading Success with FOREX PRO Account Management! 🌟
        </Typography>
      </Box>

      <Typography variant="h6" paragraph>
        Are you passionate about trading but find yourself overwhelmed by market
        fluctuations and time constraints? At FOREX PRO, we understand that
        navigating the forex market can be challenging. That's why we offer
        professional Forex Account Management tailored to your unique investing
        needs!
      </Typography>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          🚀 Why Choose FOREX PRO for Account Management?
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" gutterBottom>
              ✅ Expert Guidance
            </Typography>
            <Typography variant="body2" paragraph>
              Our seasoned forex traders have years of experience in the
              financial markets. We leverage our expertise to manage your
              account actively, making informed decisions to help grow your
              investment.
            </Typography>

            <Typography variant="body1" gutterBottom>
              ✅ Personalized Strategies
            </Typography>
            <Typography variant="body2" paragraph>
              We believe that every trader is unique. Our team will develop a
              customized trading strategy based on your financial goals, risk
              tolerance, and trading preferences.
            </Typography>

            <Typography variant="body1" gutterBottom>
              ✅ Transparent Reporting
            </Typography>
            <Typography variant="body2" paragraph>
              Stay informed with regular performance reports and updates. We
              believe in transparency, so you’ll always know how your account is
              performing and our trading decisions.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="body1" gutterBottom>
              ✅ Time-Saving Convenience
            </Typography>
            <Typography variant="body2" paragraph>
              Let us handle the complexities of forex trading while you focus on
              what matters most to you. Enjoy a hands-off approach, knowing that
              your investments are in capable hands.
            </Typography>

            <Typography variant="body1" gutterBottom>
              ✅ Risk Management Focused
            </Typography>
            <Typography variant="body2" paragraph>
              At FOREX PRO, we prioritize the safety of your capital. Our risk
              management strategies are designed to protect your investments
              while aiming for consistent returns.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box textAlign="center" mt={4} mb={4}>
        <Typography variant="h5" gutterBottom>
          🔥 Join the Many Satisfied Clients! 🔥
        </Typography>
        <Typography variant="body1" paragraph>
          Take the stress out of trading and let our experienced team help you
          achieve your financial goals. With FOREX PRO, you’re not just another
          account; you’re a valued partner in growth.
        </Typography>
        <Typography variant="h6" paragraph>
          ✨ Get Started Today! ✨
        </Typography>

        <Button
          variant="contained"
          color="primary"
          size="large"
          href="https://YourWebsiteURL.com"
        >
          Sign Up Now for a FREE Consultation
        </Button>
      </Box>

      <Box textAlign="center" mt={4}>
        <Typography variant="h6" gutterBottom>
          Your success in forex trading starts here! Let’s embark on this
          journey together!
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutUs;
