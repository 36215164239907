import { Box } from "@mui/material";
import React from "react";
import DataGridWidget from "../../components/DataGridWidget";
import AdminTransactionsTable from "../../components/DepositsTable";

export default function AdminDeposits() {
  return (
    <Box>
      <AdminTransactionsTable type="deposit" />
    </Box>
  );
}
