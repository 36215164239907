import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import AdminDashboardLayout from "./layouts/AdminLayout";
import UserDashboardLayout from "./layouts/UsersLayout";
import ProtectedRoute from "./lib/AuthHook";
import AdminDeposits from "./pages/Admin/AdminDeposit";
import AdminProfile from "./pages/Admin/AdminProfile";
import AdminWithdrawals from "./pages/Admin/AdminWithdrawals";
import AgentsManagement from "./pages/Admin/AgentsManagement";
import Dashboard from "./pages/Admin/Dashboard";
import ConfirmOtp from "./pages/Auth/ConfirmOtp";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Signin from "./pages/Auth/Signin";
import Signup from "./pages/Auth/Signup";
import VerifyEmail from "./pages/Auth/VerifyEmail";
import HomePage from "./pages/HomePage";
import UserDashboard from "./pages/User/Dashboard";
import UserDeposits from "./pages/User/Deposits";
import UserProfile from "./pages/User/Profile";
import UserWithrawals from "./pages/User/Withrawals";
import SignalsPage from "./pages/User/Signals";
import SignalsPageAdmin from "./pages/Admin/Signals";
import SignalDetailPage from "./pages/User/SignalDetail";
import AdminTrades from "./pages/Admin/AdminTrades";
import ReferralPage from "./pages/User/Referals";
import TimeIntervals from "./pages/Admin/TimeFrames";
import AdminUsers from "./pages/Admin/Users";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminDashboardLayout />
            </ProtectedRoute>
          }
        >
          {" "}
          <Route index element={<Navigate to="dashboard" />} />
          <Route index path="dashboard" element={<Dashboard />} />
          <Route path="signals" element={<SignalsPageAdmin />} />
          <Route path="trades" element={<AdminTrades />} />
          <Route path="intervals" element={<TimeIntervals />} />
          <Route index path="deposits" element={<AdminDeposits />} />
          <Route index path="users" element={<AdminUsers />} />
          <Route path="profile" element={<AdminProfile />} />
          <Route path="withdrawals" element={<AdminWithdrawals />} />
          <Route path="agents" element={<AgentsManagement />} />
        </Route>
        <Route
          path="/user"
          element={
            <ProtectedRoute>
              <UserDashboardLayout />
            </ProtectedRoute>
          }
        >
          {" "}
          <Route index element={<Navigate to="dashboard" />} />
          <Route index path="dashboard" element={<UserDashboard />} />
          <Route path="signals" element={<SignalsPage />} />
          <Route path="settings" element={<UserProfile />} />
          <Route path="referrals" element={<ReferralPage />} />
          <Route path="withdrawals" element={<UserWithrawals />} />
          <Route path="deposits" element={<UserDeposits />} />{" "}
          <Route path="signals/:id" element={<SignalDetailPage />} />
        </Route>
        <Route path="/signin" element={<Signin />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password" element={<ForgotPassword />} />
        <Route path="/confirm-otp" element={<ConfirmOtp />} />

        {/* <Route path="/privacypolicy" element={<PrivacyPolicy />} /> */}
        {/* <Route path="/delete_account" element={<DeleteAccount />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
