import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Collapse,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Logout,
  Notifications,
  Person,
  Settings,
  SettingsSuggest,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../components/Logo";
import User from "../components/User";
import { logOut } from "../store/actions/auth";

const drawerWidth = 240;

const AdminDashboardLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [currentRoute, setCurrentRoute] = useState(null);
  const location = useLocation();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    const extractRouteName = (path) => {
      const parts = path.split("/").filter(Boolean);
      return parts.length > 1 ? parts[1] : parts[0];
    };
    setCurrentRoute(extractRouteName(location.pathname));
  }, [location]);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const handleMenuClose = () => setAnchorEl(null);

  const handleProfileLogout = () => {
    dispatch(logOut());
    handleMenuClose();
  };

  const handleClickAvatar = (event) => setAnchorEl(event.currentTarget);

  const drawerContent = (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Toolbar>
        <Logo />
      </Toolbar>
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <List>
          {[
            { text: "Dashboard", path: "/admin/dashboard" },
            { text: "Trades", path: "/admin/trades" },
            { text: "Signals", path: "/admin/signals" },
            { text: "Users", path: "/admin/users" },
            { text: "Deposit Requests", path: "/admin/deposits" },
            { text: "Withdrawal Requests", path: "/admin/withdrawals" },
            { text: "Agents", path: "/admin/agents" },
            { text: "Time Intervals", path: "/admin/intervals" },
          ].map((item) => (
            <ListItemButton
              key={item.text}
              component={RouterLink}
              to={item.path}
              selected={location.pathname === item.path}
            >
              <ListItemText primary={item.text} />
            </ListItemButton>
          ))}
          <ListItemButton onClick={() => setSettingsOpen(!settingsOpen)}>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Settings" />
            {settingsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                component={RouterLink}
                to="/admin/settings"
                selected={location.pathname === "/admin/settings"}
                sx={{ pl: 4 }}
              >
                <ListItemIcon>
                  <SettingsSuggest />
                </ListItemIcon>
                <ListItemText primary="General Settings" />
              </ListItemButton>
              <ListItemButton
                component={RouterLink}
                to="/admin/profile"
                selected={location.pathname === "/admin/profile"}
                sx={{ pl: 4 }}
              >
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <User />
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: 0,
          bgcolor: "transparent",
          mt: isMobile ? 0 : 2,
        }}
      >
        <Container
          maxWidth={isMobile ? false : "xl"}
          sx={
            {
              // width: isMobile ? "100%" : "auto",
            }
          }
          disableGutters={isMobile}
        >
          {" "}
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              paddingRight: "4px",
              paddingLeft: "8px",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: isMobile ?? "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box
              sx={{
                display: { xs: "flex", md: "flex" },
                gap: 1,
                alignItems: "center",
              }}
            >
              {" "}
              <Typography
                variant="h6"
                sx={{ flexGrow: 1, textTransform: "capitalize" }}
              >
                {currentRoute}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Badge badgeContent={4} color="secondary">
                <Notifications color="action" />
              </Badge>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClickAvatar}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={openMenu ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                >
                  <Avatar alt="User" src="/static/images/avatar/1.jpg" />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="admin sidebar"
      >
        {/* Temporary drawer for mobile */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawerContent}
        </Drawer>
        {/* Permanent drawer for larger screens */}
        <Drawer
          variant="permanent"
          open
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawerContent}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: isMobile ? 0.7 : 3,
          width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 4,
          sx: {
            overflow: "visible",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleMenuClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleProfileLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AdminDashboardLayout;
