const initialState = {
  user: {},
  isLoggedIn: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        ...state,
        user: action.data,
        isLoggedIn: true,
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: { ...state.user, ...action.data },
      };
    case "LOGOUT_USER":
      return {
        ...state,
        user: {},
        isLoggedIn: false,
      };

    default:
      return state;
  }
};
