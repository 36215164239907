import { Box } from '@mui/material'
import React from 'react'
import TransactionsTable from '../../components/DataGridWidget'

export default function UserWithrawals() {
  return (
    <Box>
      <TransactionsTable type="withdraw"/>
    </Box>
  )
}
