import { useRef } from "react";
import { Box, Container, Grid, OutlinedInput, TextField } from "@mui/material";

export default function OtpInput({ otp, setOtp }) {
  const inputsRef = useRef([]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (value.length <= 1 && !isNaN(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input
      if (value !== "" && index < 5) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      // Move to the previous input on backspace press
      inputsRef.current[index - 1].focus();
    }
  };

  return (
    <Grid container spacing={1} justifyContent="center" columns={6}>
      {" "}
      {otp.map((digit, index) => (
        <Grid item xs={1} sm={1} key={index}>
          {" "}
          <OutlinedInput
            inputRef={(el) => (inputsRef.current[index] = el)}
            type="text"
            value={digit}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
            variant="outlined"
            sx={{ minWidth: "40px" }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
