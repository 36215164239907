import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const Balance = ({ balance }) => {
  return (
    <Box 
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        borderRadius: 1,
        bgcolor: 'background.paper',
        boxShadow: 1,
      }}
    >
      <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
        <AccountBalanceWalletIcon />
      </Avatar>
      <Box>
        <Typography variant="body1" color="textSecondary">
          Balance
        </Typography>
        <Typography variant="h6" color="textPrimary">
          ${balance.toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
};

export default Balance;
